import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { sliderCompanies, subpageContent } from "../utils/products/subpageInformation";
import { bannerCMS } from "../utils/generalInformation";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";




// markup
const Key = () => {


    return (
        <Layout
            SEOTitle={"Key Cover Insurance - United Legal Assistance"}
            SEODescription={"Key protection has been designed to reduce the stress and worry of losing your keys, as well as the expense."}
            footerDisclaimer={""}>

            <InfoImage props={subpageContent} origin="keyCover" />
            <InfoImage props={subpageContent} origin="included" />

            <Banner props={bannerCMS} />

            <ImageCarousel props={sliderCompanies} />

        </Layout>
    )

}

export default Key